
import { loadScript, isCategoryEnabled, getCookie } from '../utility.service'

import OneTrustScript from './onetrust'
import EvergageScript from './evergage'
import AmplitudeScript from './amplitude'
import AdobeLaunchScript from './adobe-launch'
import AudioEyeScript from './audio-eye'
import BranchioScript from './branchio'
import Md5Script from './md5'
import LodashScript from './lodash'
import WuDataEventCaptureScript from './wuDataEventCapture'
import mParticleScript from './mparticle'

/**
 * Get analytics object
 * @function
 * @return analytics object
 */
const getScriptsObject = {
  "evergage": (a) => {
    return EvergageScript(a)
  },
  "onetrust": (a) => {
    return OneTrustScript(a)
  },
  "amplitude": (a) => {
    return AmplitudeScript(a)
  },
  "mParticle": (a) => {
    return mParticleScript(a)
  },
  "adobeLaunch": (a) => {
    return AdobeLaunchScript(a)
  },
  "audioEye": (a) => {
    return AudioEyeScript(a)
  },
  "branchIo": (a) => {
    return BranchioScript(a)
  },
  "md5": (a) => {
    return Md5Script(a)
  },
  "WuDataEventCapture": (a) => {
    return WuDataEventCaptureScript(a)
  },
  "lodash": (a) => {
    return LodashScript(a)
  }
}

/**
 * Analytics service: injects the third-party scripts
 * @function
 * @param {Object} ANALYTIC_OPTIONS options for third party scripts
 */
const AnalyticsService = (
  ANALYTIC_OPTIONS
) => {
  console.log(`Plugins loaded: ${Object.keys(ANALYTIC_OPTIONS.analytics).join(' | ')}`)

  const analytics = ANALYTIC_OPTIONS.analytics

  window.switches = {}
  const isGDPR = ANALYTIC_OPTIONS.isGDPRCountry
  const userConsent = getCookie("userCookieOptIn")

  for (let sc in analytics) {
    const config = analytics[sc]

    if (Object.keys(getScriptsObject).indexOf(sc) > -1) {
      const scriptConfigs = getScriptsObject[sc](config)

      if (
        config &&
        !config.beforeLoad &&
        isCategoryEnabled(config['otCategory'], isGDPR, userConsent)
      ) {
        loadScript(scriptConfigs)
      } else if (
        config &&
        config.beforeLoad
      ) {
        scriptConfigs.onload()
      }
    }
  }
}

export default AnalyticsService
