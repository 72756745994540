import React, { Suspense } from 'react'
import PropTypes from "prop-types"

import { setSwitches } from "./services/utility.service"

import HeaderComponent from './components/header.component'
import BannerComponent from './components/banner-component/banner-component'
import SignupOverlayComponent from './components/signupoverlay-component/signupoverlay.component'
const WubotComponentLazy = React.lazy(() => import('./components/wubot-component/wubot.component'))

/**
 * React App component
 *
 * @component
 * @example
 * const pageData = {
 *  seo: {
 *    title: "Page title"
 *  },
 *  headerContent: "<p>Sample header contentt</p>",
 *  content: "<p>Sample body content</p>",
 *  footerContent: "<p>Sample footer contentt</p>"
 * }
 * return (
 *  <App page-data={ pageData } />
 * )
 */
const App = (
  props
) => {
  const pageData = props['page-data']
  const appBanner = {
    appbanner: pageData && pageData['dataCountryThemeSetting'] && pageData['dataCountryThemeSetting']['nodes'] && pageData['dataCountryThemeSetting']['nodes'][0],
    language: pageData.language,
    isWuplus: pageData.template && pageData.template.templateName === 'WU + Template'
  }
  const signUpOverlayData = {
    country: pageData['country'],
    language: pageData['language'],
  }

  const switches = pageData?.datathemeSetting?.switches?.data

  // set switches in the session storage
  setSwitches(switches)

  return (
    <>
      <BannerComponent bannerData={ appBanner } />
      {
        pageData.Wubot_Enabled_Page.wubotEnabledPage === 'Yes' && (
          <Suspense fallback={<div></div>}>
            <WubotComponentLazy />
          </Suspense>
        )
      }
      <SignupOverlayComponent data={ signUpOverlayData } />
      <HeaderComponent content={ pageData.headerContent } mobileMenuContent={ pageData.mobileMenuContent } />
    </>
  )
}

App.propTypes = {
  /**
   * page-data attributes
   */
  "page-data": PropTypes.object
}

export default App
