
import  { loadStyles } from './utility.service'

/**
 * toggle css display
 * @param index integer representing the page css index
 */
const toggleCssDisplay = (
  screen
) => {
  const pageCssElem = document.getElementById(`page-css`)

  if (
    pageCssElem
  ) {
    if (
      pageCssElem.getAttribute('href') === window.pageData.pageCSS[screen]
    ) {
      return
    }

    pageCssElem.setAttribute('href', window.pageData.pageCSS[screen])

    return
  }

  let styles = []

  styles.push({
    id: `page-css`,
    href: window.pageData.pageCSS[screen],
    insertAfter: 'critical-css'
  })

  loadStyles(styles)
}

/**
 * resize function for styles based on type
 */
const resizeFnStyles = (isV2Page) => {
  let mediaSizes = [
    [0, 499.99],
    [500, 1024.99],
    [1025, 1599.99],
    [1600, 9999]
  ]
  var width = document.body.clientWidth

  if (isV2Page) {
    mediaSizes = [
      [0, 767.99],
      [768, 1023.99],
      [1024, 1279.99],
      [1280, 1599.99],
      [1600, 9999]
    ]
  }


  for (
    let index in mediaSizes
  ) {
    (width >= mediaSizes[index][0] && width < mediaSizes[index][1]) && toggleCssDisplay(index)
  }
}

/**
 * Loading Styles client-side
 */
const loadNativeStyles = () => {
  const isV2Page = window.pageData.template.templateName === 'WUDS 2.0 Template'
  window.addEventListener('resize', resizeFnStyles.bind({}, isV2Page))
  resizeFnStyles(isV2Page)

  let styles = []
  !isV2Page && styles.push({
    id: `wuds-icons-css`,
    href: window.pageData.wudsIconsCSS,
    insertAfter: 'page-css'
  })

  loadStyles(styles)
}

export default loadNativeStyles
